<template>
  <div class="IM-wrap">
    <el-tag class="toUser">当前客服代表：{{ nickname }}</el-tag>
    <el-tag type="danger" class="surTime">咨询剩余时间：{{ surTime }}</el-tag>

    <div class="conversation-wrap">
      <el-input
        id="scroll_text"
        type="textarea"
        readonly
        resize="none"
        :autosize="{ minRows: 15, maxRows: 15 }"
        v-model="conversation"
      >
      </el-input>
    </div>

    <div class="input-wrap">
      <span>请输入内容：</span>
      <el-input
        type="textarea"
        resize="none"
        :rows="5"
        v-model="inputDoc"
        @keyup.enter.native="keyDown"
      >
      </el-input>
    </div>

    <div class="btn-wrap">
      <el-button type="primary" @click="handleTalk">发送(ctrl+enter)</el-button>
      <el-button type="danger" @click="closeWindow">关闭对话框</el-button>
    </div>

    <!-- 提示付费蒙版 -->
    <div class="pay-prompt-wrap" v-show="showPayPrompt">
      <div class="pay-div">
        <span>免费咨询已过期,是否付费咨询？</span>
        <span>¥{{ payAmount }}元</span>

        <div class="pay-btns-wrap">
          <el-button @click="handleCancelPay">取消</el-button>
          <el-button type="primary" @click="handleDecidePay">购买</el-button>
        </div>
      </div>
    </div>

    <!-- 支付二维码窗口 -->
    <el-dialog
      title="微信扫描二维码支付"
      :visible.sync="showPayMask"
      width="30%"
      :before-close="handleClose"
      class="pay-info"
      :modal="false"
    >
      <div class="pay-box">
        <div id="qrcode" ref="qrcode"></div>
        <span>订单金额：{{ payAmount }}元</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { socket } from "@/utils/websocket";
import {
  getMessages,
  getUserConsultEndTime,
  getHistoryData,
  getPayData,
  getDecidePay,
  getIfPay,
} from "@/api/im";
import Cookies from "js-cookie";
import QRCode from "qrcodejs2";

export default {
  props: ["dialog_visible", "userName", "nickName", "offline"],

  watch: {
    userName: function (newData, oldData) {
      this.toUser = newData;
    },

    nickName: function (newData, oldData) {
      this.nickname = newData;

      this.conversation +=
        this.nickname +
        "为您服务，请问有什么可以帮助您的？\n" +
        "--------------\n";
    },

    offline: function (newData, oldData) {
      this.inLine = newData;
    },
  },

  data() {
    return {
      // 对话内容
      conversation: "",

      // 对话数组
      dataArry: [],

      // 用户账号
      account: "",

      // 客服名称
      toUser: "",

      // 客服昵称
      nickname: "",

      // 客服id
      toID: "",

      // 客服在线状态
      inLine: "",

      // 输入内容
      inputDoc: "",

      // 咨询剩余时间
      surTime: "",

      // 用户id
      usrId: "",

      // 提示付费蒙版可见性
      showPayPrompt: false,

      // 咨询费信息
      payInfo: null,

      // 咨询费金额
      payAmount: 0,

      // 订单编号
      orderNo: "",

      // 是否有未支付的订单
      ifNoPay: "",

      // 支付窗口可见性
      showPayMask: false,

      // 定时器，扫描支付状态
      timer: null,
    };
  },

  mounted() {
    document.addEventListener("keydown", this.keyDown);
  },

  destroyed() {
    console.log("socket close");

    // 每次离开当前界面时，清除定时器
    this.closeTimer();
  },

  methods: {
    // 初始化socket
    socketInit() {
      this.account = Cookies.get("account");
      // console.log(this.account);
      if (this.account != undefined) {
        socket.initWebSocket(
          "wss://www.hbnanjun.com/wss/" + this.account,
          this.account
        );
        //绑定接收消息方法
        socket.websocket.onmessage = this.websocketOnMessage;
        console.log("socket online");
      }
    },

    websocketOnMessage(event) {
      // console.log(event);
      //初始化界面时，主动向后台发送一次消息，获取数据
      this.websocketCount += 1;
      if (this.websocketCount === 0) {
        //this.init();
      }
      let info = JSON.parse(event.data);
      // console.log(info);
      switch (info.type) {
        case "heartbeat":
          socket.websocketState = true;
          break;
        case "message":
          this.loading = true;
          this.$nextTick(() => {
            this.consumeMessage(info);
          });
          break;
        case "pay":
          this.handlePayPrompt();
          this.ifNoPay = info.payFlag;
          break;
        case "error":
          this.loading = false;
          break;
      }
    },

    // 接收客服信息
    consumeMessage(info) {
      this.conversation +=
        info.from +
        "\n" +
        info.date.split(" ")[1] +
        "\n" +
        info.text +
        "\n" +
        "--------------\n";

      this.$nextTick(() => {
        setTimeout(() => {
          const textarea = document.getElementById("scroll_text");
          textarea.scrollTop = textarea.scrollHeight;
        }, 13);
      });
    },

    //发送消息
    handleTalk() {
      // 判断是否全是空格
      var message = this.inputDoc.replace(/\s+/g, "");
      if (message !== "") {
        let time = new Date();
        let formatDate = this.formatDate(time);
        var msg = {
          from: this.account,
          to: this.toUser,
          text: this.inputDoc,
          date: formatDate,
          type: "message",
        };
        // 发送消息
        socket.sendMsg(JSON.stringify(msg));

        this.inputDoc = "";

        this.conversation +=
          "我的消息\n" +
          msg.date.split(" ")[1] +
          "\n" +
          msg.text +
          "\n" +
          "--------------\n";

        this.$nextTick(() => {
          setTimeout(() => {
            const textarea = document.getElementById("scroll_text");
            textarea.scrollTop = textarea.scrollHeight;

            this.getSurTime();
          }, 13);
        });
      }
    },

    formatDate(value, type) {
      // 计算日期相关值
      let time = typeof value == "number" ? new Date(value) : value;
      let Y = time.getFullYear();
      let M = time.getMonth() + 1;
      let D = time.getDate();
      let h = time.getHours();
      let m = time.getMinutes();
      let s = time.getSeconds();
      let week = time.getDay();
      // 如果传递了type的话
      if (type == undefined) {
        return (
          Y +
          "-" +
          (M < 10 ? "0" + M : M) +
          "-" +
          (D < 10 ? "0" + D : D) +
          " " +
          (h < 10 ? "0" + h : h) +
          ":" +
          (m < 10 ? "0" + m : m) +
          ":" +
          (s < 10 ? "0" + s : s)
        );
      } else if (type == "week") {
        // 在quartz中 1为星期日
        return week + 1;
      }
    },

    // 获取im信息
    handleGetMsg() {
      const data = {
        userName: this.account,
        pageNum: 0,
        pageSize: 10,
      };
      getMessages(data).then((res) => {
        // console.log(res);
        this.dataArry = res.data;
        // console.log(this.dataArry);

        if (this.dataArry.length > 0) {
          this.dataArry.forEach((item) => {
            this.conversation +=
              item.dateStr.split(" ")[1] + "\n" + item.text + "\n";

            this.conversation += "--------------\n";
          });
        }
      });
    },

    // 键盘组合
    keyDown(e) {
      if (e.ctrlKey && e.keyCode == 13) {
        this.handleTalk();
      } else {
        this.textarea += "\n";
      }
    },

    // 关闭IM对话框
    closeWindow() {
      this.$emit("handleIMClose");

      Cookies.remove("toID");
    },

    // 获取剩余时间
    getSurTime() {
      this.usrId = Cookies.get("user_Id");

      let data = {
        userId: this.usrId,
      };

      getUserConsultEndTime(data).then((res) => {
        // console.log(res);
        this.surTime = res.msg;
      });
    },

    // 获取历史信息记录
    getHistoryMsg() {
      const data = {
        contactId: Cookies.get("toID"),
        userName: Cookies.get("account"),
      };
      getHistoryData(data).then((res) => {
        // console.log(res);

        this.conversation = "";
        const myCount = Cookies.get("account");

        if (res.total != 0) {
          res.data.forEach((item) => {
            if (item.from == myCount) {
              this.conversation += "我的消息";
            } else {
              this.conversation += item.from;
            }
            this.conversation += "\n";
            this.conversation += item.date;
            this.conversation += "\n";
            this.conversation += item.text;
            this.conversation += "\n";
            this.conversation += "--------------\n";
          });

          this.conversation += "以上为历史信息\n";
          this.conversation += "--------------\n";
        }
      });
    },

    // 取消付费
    handleCancelPay() {
      this.showPayPrompt = false;
      this.$emit("handleIMClose");
      this.$router.go(0);
    },

    // 获取付费信息
    handlePayPrompt() {
      this.showPayPrompt = true;

      const data = {
        userId: Cookies.get("user_Id"),
      };

      getPayData(data).then((res) => {
        // console.log(res);
        this.payInfo = res.data;
        this.payAmount = res.data.totalAmount;
      });
    },

    // 确定购买付费咨询
    handleDecidePay() {
      // console.log(this.ifNoPay);
      if (this.ifNoPay == "01") {
        this.$confirm("有未支付的咨询订单, 是否前往订单中心?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({ path: "/MemberCenter/OrderAndInfo" });
          })
          .catch(() => {});
      } else {
        this.showPayMask = true;
        const data = {
          businessId: 0,
          orderType: "04",
          userId: Cookies.get("user_Id"),
          payType: this.payInfo.payType,
          amount: this.payInfo.amount,
          totalAmount: this.payAmount,
          platformType: "pc",
        };
        getDecidePay(data).then((res) => {
          // console.log(res);
          this.orderNo = res.data.orderNo;
          this.handleCode(res.data.codeUrl);
          this.setTimer();
        });
      }
    },

    // 生成微信支付二维码
    handleCode(url) {
      new QRCode("qrcode", {
        width: 200,
        height: 200,
        text: url,
      });
    },

    // 定时扫描支付状态
    setTimer() {
      if (this.timer == null) {
        this.timer = setInterval(() => {
          getIfPay(this.orderNo).then((res) => {
            // console.log(res);
            if (res.data.state == 1) {
              this.showPayMask = false;
              this.showPayPrompt = false;
              this.getSurTime();
              this.getHistoryMsg();
            }
          });
        }, 1200);
      }
    },

    // 关闭支付窗口
    handleClose() {
      this.$refs.qrcode.innerHTML = "";
      this.showPayMask = false;

      this.closeTimer();
    },

    // 关闭定时器
    closeTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style scoped>
.IM-wrap {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 800px;
  background: #fff url("../../assets/images/im-bg.png") no-repeat 50% 28%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
  z-index: 99;
}

.toUser {
  margin: 5px;
}

::v-deep .el-textarea__inner {
  background-color: transparent !important;
}

.input-wrap {
  margin: 15px 0;
}

.input-wrap span {
  line-height: 2;
}

.btn-wrap {
  float: right;
}

.pay-prompt-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(56, 56, 56, 0.7);
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 50%;
  background-color: #fff;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.pay-div span:nth-child(2) {
  margin-top: 20px;
  color: red;
}

.pay-btns-wrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pay-info .pay-box {
  margin: 30px;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-info .pay-box span {
  margin-top: 10px;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: red;
}
</style>
