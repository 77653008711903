<template>
  <div class="foot-wrap">
    <div class="top">
      <div class="about">
        <ul>
          <li>关于我们</li>
          <router-link to="/AboutUs/SituationAndHonor?#situation"
            >南君概览</router-link
          >
          <router-link to="/AboutUs/SituationAndHonor?#honor"
            >专业荣誉</router-link
          >
          <router-link to="">社会责任</router-link>
        </ul>
      </div>
      <div class="service">
        <ul>
          <li>服务范围</li>
          <router-link to="/ServiceCenter/introduce?type=法律服务"
            >法律服务</router-link
          >
          <router-link to="/ServiceCenter/introduce?type=财税服务"
            >财税服务</router-link
          >
          <router-link to="/ServiceCenter/introduce?type=知识产权"
            >知识产权</router-link
          >
          <router-link to="/ServiceCenter/introduce?type=金融服务"
            >金融服务</router-link
          >
        </ul>
      </div>
      <div class="news">
        <ul>
          <li>新闻中心</li>
          <router-link to="/Common/list?type=qyxw">企业新闻</router-link>
          <router-link to="/Common/list?type=hydt">行业动态</router-link>
        </ul>
      </div>
      <div class="contact">
        <ul>
          <li>咨询电话：027-87610831</li>
          <li>微信公众号：湖北南君律所</li>
          <li>E-mail：hbnjls@sina.com</li>
          <li>
            <div>公司地址：</div>
            <div>武汉市洪山区书城路文秀街10号中石A栋大楼2号门第5层504室</div>
          </li>
        </ul>
      </div>
      <div class="QR-code">
        <img src="../../assets/images/QR-code.png" alt="" />
        <span>扫一扫，关注我们</span>
      </div>
    </div>

    <div class="bottom">
      <div class="code-1">
        <a href="https://beian.miit.gov.cn/">鄂ICP备18003382号-2</a>
      </div>

      <div class="code-2">
        <img src="../../assets/images/ga.png" alt="" />
        <span>鄂公网安备 42010502001193号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.foot-wrap {
  margin: 0 auto 60px;
  width: 75%;
  padding: 60px 0;
}

.top {
  width: 100%;
  display: flex;
}

.about,
.service,
.news {
  margin-right: 6%;
  width: 85px;
  height: 100px;
}

.contact {
  width: 272px;
  height: 100px;
}

.about ul li:nth-child(1),
.service ul li:nth-child(1),
.news ul li:nth-child(1),
.contact ul li:nth-child(1) {
  margin-bottom: 17px;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
}

a {
  display: block;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
  line-height: 2;
}

a:hover {
  text-decoration: underline;
  text-underline-offset: 6px;
}

.contact ul li:nth-child(n + 2) {
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
  line-height: 2;
}

.contact ul li:nth-child(4) {
  display: flex;
}

.contact ul li:nth-child(4) div:nth-child(1) {
  width: 50%;
}

.QR-code {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 138px;
  height: 165px;
}

.QR-code img {
  width: 100%;
  height: 141px;
}

.QR-code span {
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
}

.bottom {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}

.code-2 {
  margin-left: 20px;
}

.code-2 span {
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #1f1f1f;
}
</style>
