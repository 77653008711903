<template>
  <div class="side-wrap">
    <div class="phone" @mouseover="mouseOver1" @mouseleave="mouseLeave1">
      <i class="iconfont icon-dianhua"></i>
      <transition name="draw">
        <div class="mainDiv" v-show="show1">
          <i class="iconfont icon-dianhua"></i>
          <span>027-87610831</span>
          <div class="line"></div>
        </div>
      </transition>
    </div>
    <div class="talk" @mouseover="mouseOver2" @mouseleave="mouseLeave2">
      <i class="iconfont icon-talk-line"></i>
      <transition name="draw">
        <div class="mainDiv" v-show="show2">
          <i class="iconfont icon-talk-line"></i>
          <span class="system-url" @click="showServiceList">在线客服</span>
          <!-- <div class="serviceUserList">
            <div
              class="UserItem"
              v-for="item in serviceList"
              :key="item.userId"
            >
              <img
                src="../../assets/images/service-offline.png"
                alt=""
                v-if="item.onlineFlag == '00'"
              />
              <img src="../../assets/images/service-online.png" alt="" v-else />
              <span
                @click="
                  showDialog(item.userName, item.onlineFlag, item.nickName)
                "
                >{{ item.nickName }}</span
              >
            </div>
          </div> -->
        </div>
      </transition>
    </div>
    <div class="system" @mouseover="mouseOver4" @mouseleave="mouseLeave4">
      <i class="iconfont icon-xitongpeizhi"></i>
      <transition name="draw">
        <div class="mainDiv" v-show="show4">
          <i class="iconfont icon-xitongpeizhi"></i>
          <span class="system-url" @click="gotoSystem">管理后台</span>
        </div>
      </transition>
    </div>
    <div class="QR" @mouseover="mouseOver3" @mouseleave="mouseLeave3">
      <i class="iconfont icon-ico"></i>
      <transition name="draw">
        <div class="mainDiv2" v-show="show3"></div>
      </transition>
    </div>
    <div class="to-top" v-show="goTopShow" @click="toTop">
      <i class="iconfont icon-huidaodingbu"></i>
    </div>

    <ServicesList
      v-show="list_visible"
      ref="ServicesList"
      @handleShowIM="handleShowIM"
      @handleClose="handleClose"
    ></ServicesList>

    <IM
      v-show="dialog_visible"
      ref="IM"
      :dialog_visible="dialog_visible"
      :userName="userName"
      :nickName="nickName"
      :offline="offline"
      @handleIMClose="handleIMClose"
    ></IM>
  </div>
</template>

<script>
import IM from "../IMWindow/index";
import ServicesList from "../ServicesList/index";
import Cookies from "js-cookie";

export default {
  components: {
    IM,
    ServicesList,
  },

  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,

      scrollTop: "",
      goTopShow: false,

      // 客服列表
      serviceList: [],

      // 客服账号
      userName: "",

      // 客服昵称
      nickName: "",

      // 客服id
      toID: "",

      // 客服是否离线
      offline: "",

      // 控制客服对话框组件显示与隐藏
      dialog_visible: false,

      // 客服列表显示与隐藏
      list_visible: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.getServiceList();
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    mouseOver1() {
      this.show1 = true;
    },

    mouseLeave1() {
      this.show1 = false;
    },

    mouseOver2() {
      this.show2 = true;
    },

    mouseLeave2() {
      this.show2 = false;
    },

    mouseOver3() {
      this.show3 = true;
    },

    mouseLeave3() {
      this.show3 = false;
    },

    mouseOver4() {
      this.show4 = true;
    },

    mouseLeave4() {
      this.show4 = false;
    },

    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 300) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },

    // 回到顶部
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;

      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    // 客服选择
    // showDialog(name, onlineFlag, nickName) {
    //   if (!Cookies.get("Admin-Token")) {
    //     this.$confirm("请进行用户登录", "系统提示", {
    //       confirmButtonText: "去登录",
    //       cancelButtonText: "取消",
    //       type: "warning",
    //     })
    //       .then(() => {
    //         this.$router.push({ path: "/MemberCenter/LoginAndReg" });
    //       })
    //       .catch(() => {});
    //   } else {
    //     this.offline = onlineFlag;
    //     this.userName = name;
    //     this.nickName = nickName;
    //     this.dialog_visible = true;
    //   }
    // },

    // 关闭律师列表对话框
    handleClose() {
      this.list_visible = false;
    },

    // 关闭IM对话框
    handleIMClose() {
      this.dialog_visible = false;
    },

    // 显示客服列表
    showServiceList() {
      if (!Cookies.get("Admin-Token")) {
        this.$confirm("请进行用户登录", "系统提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({ path: "/MemberCenter/LoginAndReg" });
          })
          .catch(() => {});
      } else {
        this.list_visible = true;
        this.$refs.ServicesList.getTypeData();
      }
    },

    // 跳转到后台系统
    gotoSystem() {
      window.open("http://www.hbnanjun.com:8088/", "_self");
    },

    // 显示即时通信对话框
    handleShowIM(item) {
      // console.log(item);
      Cookies.set("toID", item.userName);
      this.userName = item.userName;
      this.nickName = item.nickName;
      this.dialog_visible = true;

      this.$refs.IM.getHistoryMsg();
      this.$refs.IM.socketInit();
      this.$refs.IM.getSurTime();
    },
  },
};
</script>

<style scoped>
.side-wrap {
  position: fixed;
  top: 200px;
  right: 0;
  width: 58px;
  z-index: 3;
}

.side-wrap .phone,
.side-wrap .talk,
.side-wrap .QR,
.side-wrap .system,
.side-wrap .to-top {
  position: relative;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background: #1f1f1f;
  cursor: pointer;
}

.side-wrap .phone i,
.side-wrap .talk i,
.side-wrap .QR i,
.side-wrap .system i,
.side-wrap .to-top i {
  color: #fff;
  font-size: 30px;
}

.mainDiv {
  position: absolute;
  top: 0;
  right: 0;
  width: 180px;
  height: 100%;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
}

.mainDiv i,
.mainDiv span,
.system-url {
  color: #fff;
  white-space: nowrap;
}

.mainDiv i {
  margin: 0 10px;
}

.mainDiv .line {
  margin-top: -20px;
  margin-left: 3px;
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.mainDiv2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 172px;
  height: 172px;
  background-color: #1f1f1f;
  z-index: 4;
  background-image: url("../../assets/images/QR-code.png");
  background-repeat: no-repeat;
  background-position: center center;
}

/* .serviceUserList {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1f1f1f;
  z-index: 4;
} */

/* .UserItem {
  margin: 10px 0 10px 10px;
  display: flex;
  align-items: center;
} */

.service-online {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

/* .UserItem :hover {
  color: #ff8416;
} */

.draw-enter-active,
.draw-leave-active {
  transition: all 1s ease;
}

.draw-enter,
.draw-leave-to {
  width: 0;
}
</style>
