import request from '@/utils/request'

// 获取im信息
export function getMessages(data) {
    return request({
        url: '/im/userMsgList',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 获取类型列表
export function getTypes(data) {
    return request({
        url: `/system/dict/data/type/cms_case_type`,
        method: 'get',
        data: data
    })
}

// 获取在线律师列表
export function getOnlineLawyer(data) {
    return request({
        url: '/im/getCustServListByCaseType',
        method: 'post',
        data: data
    })
}

// 获取剩余时间
export function getUserConsultEndTime(data) {
    return request({
        url: '/cust/consult/getUserConsultEndTime',
        method: 'post',
        data: data
    })
}

// 获取历史信息记录
export function getHistoryData(data) {
    return request({
        url: '/im/userMsgList',
        method: 'post',
        data: data
    })
}

// 咨询费用信息
export function getPayData(data) {
    return request({
        url: '/member/getConsultFees',
        method: 'post',
        data: data
    })
}

// 确定购买咨询费用
export function getDecidePay(data) {
    return request({
        url: '/member/orderPay',
        method: 'post',
        data: data
    })
}

// 判断是否支付咨询费
export function getIfPay(orderNo) {
    return request({
        url: `/cms/order/detail/${orderNo}`,
        method: 'get',
    })
}