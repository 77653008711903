<template>
  <div class="nav-wrap">
    <!-- logo -->
    <div class="home-logo">
      <img src="@/assets/images/logo-color.png" alt="" />
      <div class="title">
        <span>湖北南君律师事务所</span>
        <span>HUBEI NANJUN LAW FIRM</span>
      </div>
    </div>

    <div class="nav-box-wrap">
      <div class="nav-box">
        <div :class="['menus', { active: path === '/' }]" id="nav_1">
          <router-link to="/">首页</router-link>
        </div>
        <div
          :class="[
            'menus',
            {
              active:
                path.indexOf('/AboutUs') != -1 ||
                contentType.indexOf('hdzx') != -1,
            },
          ]"
          id="nav_2"
          @mouseenter="showSubMenu_1"
          @mouseleave="hideSelf_1"
        >
          <a>关于我们</a>
          <div
            class="subMenu"
            v-show="ifShowSubMenu_1"
            @mouseenter="showSubMenu_1"
            @mouseleave="hideSelf_1"
          >
            <ul>
              <li>
                <router-link to="/AboutUs/SituationAndHonor?#situation"
                  >南君概览</router-link
                >
              </li>
              <li>
                <a @click="handleHonor">专业荣誉</a>
              </li>
              <li>
                <router-link to="/Common/list?type=hdzx">活动中心</router-link>
              </li>
              <li>
                <router-link to="/AboutUs/ContactUs">联系我们</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div
          :class="['menus', { active: path.indexOf('/MemberCenter') != -1 }]"
          id="nav_3"
        >
          <router-link to="/MemberCenter/LoginAndReg">会员中心</router-link>
        </div>
        <div
          :class="['menus', { active: path.indexOf('/ServiceCenter') != -1 }]"
          id="nav_4"
        >
          <router-link to="/ServiceCenter/index">服务中心</router-link>
        </div>
        <div
          :class="['menus', { active: path.indexOf('/ResourceCenter') != -1 }]"
          id="nav_5"
          @mouseenter="showSubMenu_3"
          @mouseleave="hideSelf_3"
        >
          <a>资源中心</a>
          <div
            class="subMenu"
            v-show="ifShowSubMenu_3"
            @mouseenter="showSubMenu_3"
            @mouseleave="hideSelf_3"
          >
            <ul>
              <li>
                <router-link to="/ResourceCenter/DocumentIndex"
                  >文档中心</router-link
                >
              </li>
              <li>
                <router-link to="/ResourceCenter/VideoIndex"
                  >视频中心</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div
          :class="['menus', { active: path.indexOf('/Team') != -1 }]"
          id="nav_6"
        >
          <router-link to="/Team/index">专业团队</router-link>
        </div>
        <div
          :class="['menus', { active: contentType.indexOf('qydj') != -1 }]"
          id="nav_9"
        >
          <router-link to="/Common/list?type=qydj">党务建设</router-link>
        </div>
        <div
          :class="['menus', { active: contentType.indexOf('fwal') != -1 }]"
          id="nav_7"
        >
          <router-link to="/Common/list?type=fwal">服务案例</router-link>
        </div>
        <div
          :class="[
            'menus',
            {
              active:
                contentType.indexOf('qyxw') != -1 ||
                contentType.indexOf('hydt') != -1,
            },
          ]"
          id="nav_8"
          @mouseenter="showSubMenu_2"
          @mouseleave="hideSelf_2"
        >
          <a>新闻中心</a>
          <div
            class="subMenu"
            v-show="ifShowSubMenu_2"
            @mouseenter="showSubMenu_2"
            @mouseleave="hideSelf_2"
          >
            <ul>
              <li>
                <router-link to="/Common/list?type=qyxw">企业新闻</router-link>
              </li>
              <li>
                <router-link to="/Common/list?type=hydt">行业动态</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取当前路由
      path: "",

      // 路由参数
      contentType: "",

      // 是否显示子菜单
      ifShowSubMenu_1: false,
      ifShowSubMenu_2: false,
      ifShowSubMenu_3: false,
    };
  },

  mounted() {
    this.path = this.$route.path;
  },

  // watch: {
  //   $route(to, from) {
  //     this.path = to.path;
  //   },
  // },

  watch: {
    //监听路由地址的改变
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.type) {
          this.contentType = this.$route.query.type;
          if (this.contentType === undefined) {
            this.contentType = "";
          }
        }
      },
    },
  },

  methods: {
    // 显示子菜单
    showSubMenu_1() {
      this.ifShowSubMenu_1 = true;
    },

    showSubMenu_2() {
      this.ifShowSubMenu_2 = true;
    },

    showSubMenu_3() {
      this.ifShowSubMenu_3 = true;
    },

    // 隐藏子菜单
    hideSelf_1() {
      this.ifShowSubMenu_1 = false;
    },

    hideSelf_2() {
      this.ifShowSubMenu_2 = false;
    },

    hideSelf_3() {
      this.ifShowSubMenu_3 = false;
    },

    handleHonor() {
      this.$router.push({ path: "/AboutUs/SituationAndHonor?#honor" });

      if (window.location.hash.indexOf("honor") != -1) {
        document.getElementById("honor").scrollIntoView();
      }
    },
  },
};
</script>

<style scoped>
.nav-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-logo {
  width: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home-logo img {
  width: 70px;
  height: 70px;
}

.home-logo .title {
  margin-left: 20px;
  width: 330px;
  display: flex;
  flex-direction: column;
}

.home-logo .title span {
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
  color: #fff;
}

.home-logo .title span:nth-child(1) {
  font-size: 32px;
}

.home-logo .title span:nth-child(2) {
  font-size: 24px;
}

.nav-box-wrap {
  width: 65%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-box {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menus a {
  display: inline-block;
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: bold;
}

.menus a,
.menus a:link {
  color: #fff;
  text-decoration: none;
  position: relative;
  padding: 0px 0px;
}

.menus a::before {
  content: "";
  width: 0;
  height: 2px;
  background: #fff;
  position: absolute;
  top: -10px;
  left: 0;
  transition: all 0.2s;
}

.menus a:hover::before,
.menus a.active::before {
  width: 30%;
}

.active {
  position: relative;
}

.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  height: 2px;
  width: 30%;
  background-color: #fff;
}

#nav_2,
#nav_5,
#nav_8 {
  position: relative;
}

.subMenu {
  position: absolute;
  top: 27px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  background-color: rgba(0, 0, 0, 0.5);
}

.subMenu ul li {
  margin: 30px 0;
  text-align: center;
}
</style>
