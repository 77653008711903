<template>
  <div class="main-wrap">
    <!-- 中间内容区 -->
    <div class="contents-wrap">
      <!-- 类型分类列表 -->
      <div class="type-list" v-show="typeListShow">
        <div class="title">请选择案情类型</div>
        <div class="items-wrap">
          <div
            class="type-item"
            v-for="item in typeList"
            :key="item.dictCode"
            @click="showLawyerList(item)"
          >
            {{ item.dictLabel }}
          </div>
        </div>
      </div>

      <!-- 在线律师列表 -->
      <div
        class="lawyer-list"
        v-show="lawyerListShow"
        v-infinite-scroll="load"
        style="overflow: auto"
      >
        <div class="title">在线律师</div>
        <div class="noData" v-if="noLawyer">
          <span>当前没有在线律师</span>
        </div>
        <div class="items-wrap" v-else>
          <div class="team-item" v-for="item in teamData" :key="item.id">
            <div class="team-info-wrap">
              <div class="top">
                <div class="intro-wrap">
                  <div class="name-wrap">
                    <span>{{ item.nickName }}</span>
                    <span>{{ item.post || "律师" }}</span>
                  </div>

                  <div class="skill">
                    <span>擅长领域：{{ item.area }}</span>
                  </div>
                </div>

                <div class="logo">
                  <img src="../../assets/images/logo-color.png" alt="" />
                </div>
              </div>

              <div class="bottom" @click="handleConnection(item)">
                <div class="more-wrap">
                  <span>联系律师</span>
                  <div class="circle">
                    <i class="iconfont icon-gengduo1"></i>
                  </div>
                </div>
                <div class="more-line"></div>
              </div>
            </div>
            <div class="picture">
              <img :src="serverURL + item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btn-wrap">
      <el-button type="primary" @click="goBack" v-show="lawyerListShow"
        >返回</el-button
      >
      <el-button type="danger" @click="closeWindow">关闭对话框</el-button>
    </div>
  </div>
</template>

<script>
import { getTypes, getOnlineLawyer } from "@/api/im";
import global from "@/utils/global.js";

export default {
  data() {
    return {
      // 类型类标显示与否
      typeListShow: true,

      // 在线律师显示与否
      lawyerListShow: false,

      // 有无在线律师控制
      noLawyer: false,

      //  在线律师数据
      teamData: [],

      // 查询keywords
      type_keywords: "",

      // 类型分类列表
      typeList: [],

      // 服务器路径
      serverURL: global.httpUrl,
    };
  },

  methods: {
    // 获取类型列表
    getTypeData() {
      const data = {
        title: this.type_keywords,
      };
      getTypes(data).then((res) => {
        // console.log(res);
        this.typeList = res.data;
      });
    },

    // 关闭对话框
    closeWindow() {
      this.$emit("handleClose");
    },

    // 显示在线律师列表
    showLawyerList(item) {
      this.typeListShow = false;
      this.lawyerListShow = true;

      const data = {
        caseType: item.dictValue,
      };
      getOnlineLawyer(data).then((res) => {
        // console.log(res);
        if (res.total == 0) {
          this.noLawyer = true;
        } else {
          this.noLawyer = false;
          this.teamData = res.data;
        }
      });
    },

    // 返回
    goBack() {
      this.typeListShow = true;
      this.lawyerListShow = false;
    },

    // 联系律师
    handleConnection(item) {
      // console.log(item);
      this.$emit("handleShowIM", item);
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped>
.main-wrap {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 600px;
  height: 500px;
  background: #fff url("../../assets/images/im-bg.png") no-repeat 50% 50%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
  z-index: 99;
}

.contents-wrap {
  margin: 0 auto;
  width: 100%;
  height: 85%;
}

.type-list,
.lawyer-list {
  margin: 20px auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.type-list .title,
.lawyer-list .title {
  font-size: 20px;
}

.type-list .items-wrap,
.lawyer-list .items-wrap {
  margin-top: 50px;
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.type-list .items-wrap:after {
  content: "";
  width: 100px;
  height: 0;
  display: block;
}

.type-list .items-wrap .type-item {
  margin-bottom: 20px;
  width: 100px;
  height: 30px;
  border: 1px solid #92d4ee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lawyer-list .items-wrap .item {
  width: 100%;
  height: 150px;
}

.noData {
  display: inline-block;
  margin-top: 15%;
}

.team-wrap {
  margin: 60px auto 0 78px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.team-item {
  position: relative;
  margin-bottom: 60px;
  width: 100%;
  height: 175px;
  background: #ffffff;
}

.team-info-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #282828;
  border-radius: 10px;
  overflow: hidden;
}

.team-item .team-info-wrap .top {
  position: relative;
  width: 100%;
  height: 119px;
  background-color: #fff;
}

.team-item .team-info-wrap .top .intro-wrap {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-30%, -50%);
  width: 260px;
}

.team-item .team-info-wrap .top .intro-wrap .name-wrap {
  display: flex;
  align-items: flex-end;
}

.team-item .team-info-wrap .top .intro-wrap .name-wrap span:nth-child(1) {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.team-item .team-info-wrap .top .intro-wrap .name-wrap span:nth-child(2) {
  margin-left: 20px;
  font-size: 15px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.team-item .team-info-wrap .top .intro-wrap .skill {
  margin-top: 10px;
  font-size: 15px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.team-item .team-info-wrap .top .intro-wrap .skill span {
  display: block;
  width: 100%;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.team-item .team-info-wrap .top .logo {
  position: absolute;
  top: 13px;
  right: 21px;
  width: 40px;
  height: 40px;
}

.team-item .team-info-wrap .top .logo img {
  width: 100%;
  height: 100%;
}

.team-item .picture {
  position: absolute;
  top: -39px;
  left: -67px;
  width: 114px;
  height: 214px;
  z-index: 1;
}

.team-item .picture img {
  width: 100%;
  height: 100%;
}

.team-item .team-info-wrap .bottom {
  position: relative;
  width: 100%;
  height: 54px;
  background-color: #282828;
  cursor: pointer;
}

.team-item .team-info-wrap .bottom .more-wrap {
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
  width: 122px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-item .team-info-wrap .bottom .more-wrap span {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.circle .iconfont {
  padding-left: 3px;
  font-size: 14px;
  color: #282828;
}

.more-line {
  position: absolute;
  right: 22px;
  bottom: 7px;
  width: 243px;
  height: 2px;
  background: #ffffff;
}

.btn-wrap {
  margin-top: 10px;
  float: right;
}
</style>
